import { Controller } from "stimulus"
import Rails from "rails-ujs"
import Inputmask from "inputmask"
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"
const moment = require('moment')
// import moment from "moment"

export default class extends Controller {
  static targets = [
    "graph",
    "inventory",
    "perfs",
    "daterangeForm",
    'start',
    'end',
    "inventoryDate",
    "portfolioContractId",
    "btnExcel",
    "btnLoading",
    "btnO2S"
  ]

  connect() {
    this.previousDates = {
      start: moment(this.data.get("start")).format('YYYY-MM-DD'),
      end: moment(this.data.get("end")).format('YYYY-MM-DD')
    }

    this.setFlatpickr(this.startTarget, this.data.get("start"))
    this.setFlatpickr(this.endTarget, this.data.get("end"))

    this.inventoryDateTarget.innerText = "AU " + moment(this.previousDates.end).format('DD/MM/YYYY')
  }

  setFlatpickr(targetPicker, date) {
    flatpickr(targetPicker, {
      altInput: true,
      allowInput: true,
      altFormat: 'd/m/Y',
      dateFormat: "Y-m-d",
      locale: French,
      defaultDate: new Date(date)
    })
  }

  changeContract() {
    let dates = {
      start: moment(this.startTarget.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end: moment(this.previousDates.end).format('DD/MM/YYYY')
    }

    this.inventoryDateTarget.innerText = "AU " + dates.end

    let _this = this
    let portfolioId = this.data.get("portfolio-id")

    this.showLoading(this.inventoryTarget)
    this.displayLoadingBtnExcel()

    let inventory_url = `/portfolios/${portfolioId}/inventory?start=${dates.start}&end=${dates.end}&contract_id=${this.portfolioContractIdTarget.value}`
    Rails.ajax({
      type: "GET",
      url: inventory_url,
      success: (data) => {
        _this.inventoryTarget.innerHTML = data.html
        _this.setBtnExcel(data.export_excel_url)
        _this.hideLoading(_this.inventoryTarget)
        _this.hideLoadingBtnExcel()
      }
    })
    this.setBtnO2s(this.endTarget.value)
  }

  setBtnExcel(inventoryUrl) {
    let btnExcel = this.btnExcelTarget
    btnExcel.href = inventoryUrl
  }

  setBtnO2s(formatedDate) {
    let btnO2S = this.btnO2STarget
    let contractId = this.portfolioContractIdTarget.value
    let regex = /contract_id=(\d+)/gi

    btnO2S.href = btnO2S.href.replace(regex, "contract_id=" + contractId)
  }

  resetDates() {
    this.setFlatpickr(this.startTarget, this.previousDates.start)
    this.setFlatpickr(this.endTarget, this.previousDates.end)
  }

  submitForm(event) {
    event.preventDefault()

    let start = moment(this.startTarget.value, 'YYYY-MM-DD', true)
    let end = moment(this.endTarget.value, 'YYYY-MM-DD', true)

    let min = moment(this.data.get("min"))
    let max = moment(this.data.get("end"))

    if (!start.isValid()) {
      alert(`La date de début n'est pas valide`)
      this.resetDates()
      return false
    }

    if (!end.isValid()) {
      alert(`La date de fin n'est pas valide`)
      this.resetDates()
      return false
    }

    if (start < min) {
      alert(`La date de début ne peut être inférieure au ${min.format('DD/MM/YYYY')} (date de création du profil)`)
      this.setFlatpickr(this.startTarget, this.data.get("min"))
      start = moment(this.startTarget.value, 'YYYY-MM-DD', true)
    }
    if (start > max) {
      alert(`La date de début ne peut être supérieure au ${max.format('DD/MM/YYYY')}`)
      this.setFlatpickr(this.startTarget, this.data.get("max"))
      start = moment(this.startTarget.value, 'YYYY-MM-DD', true)
    }
    if (start > end) {
      alert(`La date de début ne peut être supérieure à la date de fin`)
      this.resetDates()
      return false
    }
    if (end > max) {
      alert(`La date de fin ne peut être supérieure au ${max.format('DD/MM/YYYY')}`)
      this.setFlatpickr(this.endTarget, this.data.get("max"))
      end = moment(this.endTarget.value, 'YYYY-MM-DD', true)
    }

    let dates = {
      start: start,
      end: end
    }
    this.selectDates(dates)

    this.inventoryDateTarget.innerText = "AU " + moment(this.previousDates.end).format('DD/MM/YYYY')
  }

  selectDates(dates) {
    if (JSON.stringify(this.previousDates) === JSON.stringify(dates)) {
      return false
    }

    this.showLoading(this.inventoryTarget)
    this.displayLoadingBtnExcel()

    let _this = this
    this.previousDates = dates
    let portfolioId = this.data.get("portfolio-id")

    if (this.hasPortfolioContractIdTarget)
      { var portfolioContractId = this.portfolioContractIdTarget.value }
    else
      { var portfolioContractId = '' }

    let inventory_url = `/portfolios/${portfolioId}/inventory?start=${dates.start}&end=${dates.end}&contract_id=${portfolioContractId}`
    Rails.ajax({
      type: "GET",
      url: inventory_url,
      success: (data) => {
        _this.inventoryTarget.innerHTML = data.html
        _this.setBtnExcel(data.export_excel_url)
        _this.hideLoading(_this.inventoryTarget)
        _this.hideLoadingBtnExcel()
      }
    })

    let el = this.element.querySelector('[data-controller="asset-breakdown-graph"]')
    let ctrler = this.application.getControllerForElementAndIdentifier(el, "asset-breakdown-graph")
    ctrler.showLoading()

    let asset_breakdowns_url = `/portfolios/${portfolioId}/asset_breakdown.json?start=${dates.start}&end=${dates.end}`
    Rails.ajax({
      type: "GET",
      url: asset_breakdowns_url,
      success: (data) => {
        let element = _this.element.querySelector('[data-controller="asset-breakdown-graph"]')
        let controller = _this.application.getControllerForElementAndIdentifier(element, "asset-breakdown-graph")
        controller.displayGraph(data)
        // controller.updateGraph(data)
      }
    })

    this.showLoading(this.perfsTarget)
    let perfs_url = `/portfolios/${portfolioId}/perfs?start=${dates.start}&end=${dates.end}&benchmark[id]=${this.data.get("benchmark-id")}`
    Rails.ajax({
      type: "GET",
      url: perfs_url,
      success: (data) => {
        _this.perfsTarget.innerHTML = data.html
        _this.hideLoading(this.perfsTarget)
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      }
    })

    let element = this.element.querySelector('[data-controller="performance-graph"]')
    let controller = this.application.getControllerForElementAndIdentifier(element, "performance-graph")
    controller.showLoading()

    let performance_url = `/portfolios/${portfolioId}/performance.json?start=${dates.start}&end=${dates.end}&benchmark[id]=${this.data.get("benchmark-id")}`

    Rails.ajax({
      type: "GET",
      url: performance_url,
      success: (data) => {
        let element = _this.element.querySelector('[data-controller="performance-graph"]')
        let controller = _this.application.getControllerForElementAndIdentifier(element, "performance-graph")

        // controller.updateGraph(data)
        controller.displayGraph(data)
        // controller.loaded = true
      }
    })
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }

  loadingElementSm() {
    return `
    <div>
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    `
  }

  showLoading(el) {
    el.insertAdjacentHTML('beforeend', this.loadingElement())
  }

  hideLoading(el) {
    if (el.querySelector('.loading')) {
      el.querySelector('.loading').remove()
    }
  }

  displayLoadingBtnExcel() {
    this.btnExcelTarget.classList.add('d-none')
    this.btnLoadingTarget.innerHTML = this.loadingElementSm()
    this.btnLoadingTarget.classList.remove('d-none')
  }

  hideLoadingBtnExcel() {
    this.btnLoadingTarget.classList.add('d-none')
    this.btnLoadingTarget.innerHTML = ''
    this.btnExcelTarget.classList.remove('d-none')
  }

}
