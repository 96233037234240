import { Controller } from "stimulus"
import Rails from "rails-ujs"
import Highcharts from "highcharts"
const moment = require('moment')

export default class extends Controller {

  static targets = [
    'loading'
  ]

  connect() {
    this.showLoading()
    this.graphId = this.context.element.id

    let _this = this
    
    let isNavs = _this.data.get("is-navs")

    if (isNavs == 'false') {
      this.hideLoading()
      this.showNavsError()

      return false
    }

    var observer = new IntersectionObserver(function(entries) {
      if (_this.loaded) { return false }

    	if (entries[0].isIntersecting === true) {

        let portfolioId = _this.data.get("portfolio-id")
        _this.chart = Highcharts.chart(_this.context.element, _this.options({ portfolio: [] }))
        Highcharts.setOptions({
          lang: {
            months: [
              'Janvier', 'Février', 'Mars', 'Avril',
              'Mai', 'Juin', 'Juillet', 'Août',
              'Septembre', 'Octobre', 'Novembre', 'Décembre'
            ],
            shortMonths: [
              'Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'
            ]
          }
        })
        _this.showLoading()
        let benchmarkId = ""
        if (_this.data.get('benchmark-id')) {
          benchmarkId = `?benchmark[id]=${_this.data.get('benchmark-id')}`
        }

        Rails.ajax({
          type: "GET",
          url: `/portfolios/${portfolioId}/performance.json${benchmarkId}`,
          success: (data) => {
            _this.displayGraph(data)
            _this.loaded = true
          }
        })
      }
    }, { threshold: [0] })

    observer.observe(this.context.element)
  }

  options(data) {
    let options = {
      chart: {
        type: 'line',
        spacingBottom: 0,
        spacingLeft: 0,
        height: 250
      },
      margin: 0,
      title: {
        text: null
      },
      legend: { enabled: false },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        type: 'datetime',
        lineColor: "#D6E1EB",
        alignTicks: false,
        tickColor: "#D6E1EB",
        labels: {
          style: {
            color: "#8694A2",
            fontSize: 10
          }
        }
      },
      yAxis: {
        title: {
          text: 'Valeur',
          style: {
            color: "#8694A2"
          }
        },
        labels: {
          style: {
            color: "#8694A2",
            fontSize: 10
          }
        },
        lineWidth: 1,
        lineColor: "#A1AEBB",
        startOnTick: true,
        endOnTick: true,
        minTickInterval: 0.5,
        tickColor: "#8694A2",
        plotLines: [{
          color: '#A1AEBB',
          width: 1,
          value: 100,
          zIndex: 2
        }]
      },
      loading: {
        labelStyle: {
          color: 'white'
        },
        style: {
          backgroundColor: 'gray'
        }
      },
      lang: {
        loading: "Chargement..."
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        crosshairs: [true,true],
        useHTML: true,
        formatter: function () {
          moment.locale('fr')
          return this.points.reduce(function (s, point) {
            return `
              ${s}
              <div class="mt-2 ${point.series.name == "Profil" ? "text-primary" : "text-muted" }">
                <div class="d-flex align-items-center text-muted">
                  <div class="mr-1" style="width: 10px; height: 10px; background: ${point.series.color}"></div>
                  ${point.series.name}
                </div>
                <div class="font-lg line-height-sm ${point.series.name == "Profil" ? "font-weight-medium" : "" }">${point.y.toFixed(2)}</div>
              </div>
            `
          }, `<div class="text-center">${moment(this.x).format("D MMMM YYYY")}</div>`);
        },
        shared: true
      },
      series: []
    }
    if (this.data.get("simple")) {
      options['chart']['height'] = 166
      options['margin'] = [0, 0, 0, 0]
    }
    if (data.portfolio.length > 0) {
      options.series.push({
          type: 'line',
          name: 'Profil',
          data: data.portfolio,
          color: "#FFC83C",
          zIndex: 2
      })
    }
    if (data.category_reference) {
      options.series.push({
        type: 'line',
        name: "Indice",
        data: data.category,
        color: "#2619BE"
      })
    }

    return options
  }

  displayGraph(data) {
    this.chart = Highcharts.chart(this.context.element, this.options(data))
  }

  updateGraph(data) {
    if (this.chart.series !== undefined) {
      this.chart.series[0].setData(data.portfolio)
      if (data.category_reference) {
        this.chart.series[1].setData(data.category)
      }
      this.chart.redraw()
      this.hideLoading()

    }
  }
  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }
  showLoading() {
    this.context.element.insertAdjacentHTML('beforeend', this.loadingElement())
  }
  hideLoading() {
    this.context.element.querySelector('.loading').remove()
  }

  showNavsError() {
    this.context.element.insertAdjacentHTML('beforeend', this.NavsErrorElement())
  }
  NavsErrorElement() {
    return `
      <div class="error-nav bg-white d-flex justify-content-center align-items-center">
        <div class="text-center" role="status" style="width: 30rem; height: 6rem; padding-top:30px;">
        <span class="badge badge-light-grey">
          calcul en cours
        </span>
        </div>
      </div>
    `
  }
}
